import { css } from '@emotion/react'

import type { DividerBlockContent, PageBlock } from '@microsites/types'

enum DividerPadding {
  SMALL = 8,
  MEDIUM = 16,
  LARGE = 24,
}

interface DividerBlockProps {
  block: PageBlock
}

/**
 * Component for rendering a divider on microsite page.
 */
const DividerBlock: React.FC<DividerBlockProps> = ({ block }) => {
  const { color, padding, size, line_weight } =
    block.content as DividerBlockContent
  const paddingAmount =
    (padding === 'small' && DividerPadding.SMALL) ||
    (padding === 'medium' && DividerPadding.MEDIUM) ||
    (padding === 'large' && DividerPadding.LARGE)

  return (
    <hr
      css={css`
        border: none;
        margin: 0 auto;
        opacity: 0.5;
        border-top: ${line_weight}px solid ${color};
        margin-top: ${paddingAmount}px;
        padding-bottom: ${paddingAmount}px;
        width: ${(size === 'small' && 70) ||
        (size === 'medium' && 85) ||
        (size === 'full' && 100)}%;
      `}
    />
  )
}

export default DividerBlock
