import styled from '@emotion/styled'

export const NavigationContainer = styled.div<{
  alignRight?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: ${({ alignRight }) =>
    alignRight ? 'flex-end' : 'flex-start'};
  height: 100%;
  width: 100%;
`
