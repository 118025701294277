import { useMemo } from 'react'

import { css } from '@emotion/react'

import { Image } from '@core/components/Image'
import ShareIcon from '@images/embed/share@2x.png'
import FacebookIcon from '@images/embed/social/color/facebook@2x.png'
import InstagramIcon from '@images/embed/social/color/instagram@2x.png'
import LinkedInIcon from '@images/embed/social/color/linkedin@2x.png'
import SnapchatIcon from '@images/embed/social/color/snapchat@2x.png'
import TiktokIcon from '@images/embed/social/color/tiktok@2x.png'
import TwitterIcon from '@images/embed/social/color/twitter@2x.png'
import YoutubeIcon from '@images/embed/social/color/youtube@2x.png'
import type { SocialLink } from '@microsites/types'

interface SocialImageProps {
  link: SocialLink
}

const socialImageStyles = css`
  width: 40px;
  height: 40px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 2px;
  background-color: rgba(64 64 64 / 50%);
`

/**
 * Sub-component used in the microsite page footer social button to render social icon.
 */
const SocialImage: React.FC<SocialImageProps> = ({ link }) => {
  const src = useMemo(() => {
    switch (link.type) {
      case 'facebook':
        return FacebookIcon
      case 'instagram':
        return InstagramIcon
      case 'twitter':
        return TwitterIcon
      case 'snapchat':
        return SnapchatIcon
      case 'linkedin':
        return LinkedInIcon
      case 'tiktok':
        return TiktokIcon
      case 'youtube':
        return YoutubeIcon
      default:
        return ShareIcon
    }
  }, [link.type])

  return (
    <div css={socialImageStyles}>
      <Image
        src={src}
        alt={link.type}
        width={50}
        height={50}
        style={{ objectFit: 'cover', objectPosition: 'center' }}
      />
    </div>
  )
}

export default SocialImage
