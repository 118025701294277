import type {
  FWEmbedFeedConfig,
  FWPageBlock,
  FWPageBlockContent,
} from '@microsites/types'
import { formatFWConfig } from '@microsites/utils/microsites/formatFWConfig'
import { formatFWContent } from '@microsites/utils/microsites/formatFWContent'

interface EmbedFeedProps {
  username: string
  block: FWPageBlock
  mode: 'row' | 'grid'
}

/**
 * Component for rendering fw-embed-feed on a microsite page
 */
const FWEmbedFeed: React.FC<EmbedFeedProps> = ({ username, block, mode }) => {
  const content = formatFWContent(block.content as FWPageBlockContent)
  const config = formatFWConfig(block.config as FWEmbedFeedConfig)
  return (
    <fw-embed-feed
      branding="false"
      channel={username}
      mode={mode}
      {...content}
      {...config}
    />
  )
}

export default FWEmbedFeed
