/**
 * @fileoverview
 * i18n module for use with embeds.
 *
 * Provides:
 * - i18next instance to be imported asynchronously by translation hooks.
 * - translation context hook for reusable components.
 *
 * Note: Do not export `setup/getInstance` here to
 * avoid i18next to be bundled into `fwn.js`.
 */

export { TranslationContext, useContextTranslation } from './context'
