import {
  NavigationContainer,
  NavigationLink,
} from '@microsites/components/blocks/shared'
import type { FooterBlockContent, PageBlock } from '@microsites/types'

import SocialButton from './components/SocialButton'
import { CopyrightContainer, FooterContainer } from './FooterBlock.styles'

interface FooterBlockProps {
  block: PageBlock
}

/**
 * Component for rendering a footer on microsite page.
 */
const FooterBlock: React.FC<FooterBlockProps> = ({ block }) => {
  const { content } = block
  const { navigation, social, copyright } = content as FooterBlockContent
  return (
    <>
      <FooterContainer>
        <NavigationContainer>
          {navigation.map((navItem) => (
            <NavigationLink key={navItem.name} navItem={navItem} />
          ))}
        </NavigationContainer>
        <NavigationContainer alignRight>
          {social.map((social) => (
            <SocialButton key={social.type} link={social} />
          ))}
        </NavigationContainer>
      </FooterContainer>
      {!!copyright && (
        <>
          <hr style={{ borderColor: 'rgba(200 200 200 / 0.1)' }} />
          <CopyrightContainer>&#169; {copyright}</CopyrightContainer>
        </>
      )}
    </>
  )
}

export default FooterBlock
