import { css } from '@emotion/react'

import { Image } from '@core/components/Image'
import {
  NavigationContainer,
  NavigationLink,
} from '@microsites/components/blocks/shared'
import type { HeaderBlockContent, PageBlock } from '@microsites/types'

import { HeaderBlockContainer, HeaderLogoContainer } from './HeaderBlock.styles'

interface HeaderBlockProps {
  block: PageBlock
}

/**
 * Component for rendering a header on microsite page.
 */
const HeaderBlock: React.FC<HeaderBlockProps> = ({ block }) => {
  const content = block.content as HeaderBlockContent
  const { logo, navigation, logo_position } = content
  return (
    <HeaderBlockContainer logoPosition={logo_position}>
      {logo && (
        <HeaderLogoContainer>
          <Image
            src={logo.url}
            alt={logo.alt ?? ''}
            css={css`
              height: 100%;
            `}
          />
        </HeaderLogoContainer>
      )}
      <NavigationContainer>
        {navigation.map((navItem) => (
          <NavigationLink key={navItem.name} navItem={navItem} />
        ))}
      </NavigationContainer>
    </HeaderBlockContainer>
  )
}

export default HeaderBlock
