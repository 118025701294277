import type { FWBlockConfig } from '@microsites/types'

/**
 * Helper function to format config received from the API into a format that is
 * accepted by the FW blocks.
 */
export const formatFWConfig = (config: FWBlockConfig) => {
  const formattedConfig = {}
  if (config) {
    Object.keys(config).forEach((key) => {
      if (typeof config[key] === 'boolean') {
        formattedConfig[key] = config[key] ? 'true' : 'false'
      } else if (config[key] === 'number') {
        formattedConfig[key] = `${config[key]}`
      } else {
        formattedConfig[key] = config[key]
      }
    })
  }
  return formattedConfig
}
