import { css } from '@emotion/react'

import type { NavLink } from '@microsites/types'

/**
 * Component for rendering a navigation link in microsite page header/footer.
 */
export const NavigationLink: React.FC<{ navItem: NavLink }> = ({ navItem }) => {
  return (
    <div
      css={css`
        &:not(:last-child) {
          margin-inline-end: 16px;
        }
      `}
    >
      <a href={navItem.url}>{navItem.name}</a>
    </div>
  )
}
