import type { LegacyRef } from 'react'
import React from 'react'

import styled from '@emotion/styled'

import type { PIP_IFRAME_NAME } from '@core/pictureInPicture/constants'

interface USLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  ref?: LegacyRef<HTMLAnchorElement>
  href?: string
  target?: '_self' | '_blank' | '_top' | typeof PIP_IFRAME_NAME
}
const USLink = styled.a<USLinkProps>`
  border: none;
  background: none;
  font-size: inherit;
  font-family: inherit;
  text-align: inherit;
  color: inherit;
  padding: 0;
  margin: 0;

  &:link {
    text-decoration: none !important;
  }

  &:visited {
    text-decoration: none !important;
  }

  &:hover {
    text-decoration: none !important;
  }

  &:active {
    text-decoration: none !important;
  }
`

/**
 *
 */
const UnstyledLink: React.FunctionComponent<USLinkProps> = ({
  children,
  ...rest
}) => <USLink {...rest}>{children}</USLink>

export default UnstyledLink
