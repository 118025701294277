import { css } from '@emotion/react'

import type { PageBlock, PageBlockAppearance } from '@microsites/types'
import { UITheme } from '@microsites/types'

const typeStyles = (type: PageBlock['type'], theme?: UITheme) => {
  switch (type) {
    case 'header':
      return css`
        background-color: ${theme === UITheme.LIGHT
          ? `var(--white)`
          : `var(--black)`};
        position: fixed;
        z-index: 2;
        top: 0;
        box-shadow: 0 3px 3px 0 rgba(0 0 0 / 50%);
      `
    case 'footer':
      return css`
        margin-top: 24px;
        box-shadow: 0 -3px 3px 0 rgba(0 0 0 / 50%);
      `
    default:
      return ''
  }
}

const appearanceStyles = (appearance?: PageBlockAppearance) => {
  if (!appearance) {
    return
  }
  const {
    bg_color,
    bg_image,
    bg_repeat,
    bg_attachment,
    bg_position,
    font_color,
    font_size,
  } = appearance
  return css`
    background-color: ${bg_color};
    background-image: ${bg_image ? `url(${bg_image})` : undefined};
    background-repeat: ${bg_repeat};
    background-attachment: ${bg_attachment};
    background-position: ${bg_position};
    font-size: ${font_size ? `${font_size}rem` : 'inherit'};

    * {
      color: ${font_color};
    }
  `
}

/**
 * Helper function to create the CSS styles for a page block based on its type and appearance config.
 * @param block The page block to get the styles for.
 * @returns The CSS styles for the page block.
 */
export const getPageBlockAppearance = (block: PageBlock, theme?: UITheme) => {
  return css`
    ${typeStyles(block.type, theme)}
    ${appearanceStyles(block.appearance)}
  `
}
