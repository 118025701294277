import { Markdown } from '@core/components/Markdown/next'
import type { PageBlock, ParagraphBlockContent } from '@microsites/types'

interface ParagraphBlockProps {
  block: PageBlock
}

/**
 * Component for rendering markdown on microsite page.
 */
const ParagraphBlock: React.FC<ParagraphBlockProps> = ({ block }) => {
  const content = block.content as ParagraphBlockContent
  return (
    <div style={{ textAlign: `${content.align}` }}>
      <Markdown>{content.text}</Markdown>
    </div>
  )
}

export default ParagraphBlock
