import type { AccessibleSVGProps } from '@core/svg'
import { AccessibleSVG } from '@core/svg'

/**
 * BrokenImage SVG Icon
 * source: https://react-icons.github.io/react-icons/search/#q=broken%20image
 * icon name: PiImageBrokenThin
 */
export const BrokenImage: React.FC<AccessibleSVGProps> = (props) => (
  <AccessibleSVG
    width={props.width || '100%'}
    height={props.height || '100%'}
    viewBox="0 0 150 150"
    fill="currentColor"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M88.3341 67.4531C92.016 67.4531 95.0008 64.4494 95.0008 60.7441C95.0008 57.0389 92.016 54.0352 88.3341 54.0352C84.6522 54.0352 81.6675 57.0389 81.6675 60.7441C81.6675 64.4494 84.6522 67.4531 88.3341 67.4531ZM88.3382 64.7671C90.5474 64.7671 92.3382 62.9649 92.3382 60.7417C92.3382 58.5186 90.5474 56.7164 88.3382 56.7164C86.1291 56.7164 84.3382 58.5186 84.3382 60.7417C84.3382 62.9649 86.1291 64.7671 88.3382 64.7671Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42 47.3262C40.8954 47.3262 40 48.2216 40 49.3262V100.675C40 101.78 40.8954 102.675 42 102.675H108C109.105 102.675 110 101.78 110 100.675V49.3262C110 48.2216 109.105 47.3262 108 47.3262H42ZM44.332 50.6819C43.7798 50.6819 43.332 51.1297 43.332 51.6819V98.3218C43.332 98.8741 43.7798 99.3218 44.332 99.3218H105.665C106.218 99.3218 106.665 98.8741 106.665 98.3218V51.6819C106.665 51.1297 106.218 50.6819 105.665 50.6819H44.332Z"
      fill="currentColor"
    />
    <rect
      width="3.34392"
      height="25.9357"
      rx="1"
      transform="matrix(0.704868 0.709338 -0.704868 0.709338 58.543 60.5391)"
      fill="currentColor"
    />
    <rect
      width="3.34392"
      height="38.4972"
      rx="1"
      transform="matrix(0.704868 -0.709338 0.704868 0.709338 56.6704 62.4121)"
      fill="currentColor"
    />
    <rect
      width="3.34392"
      height="11.4161"
      rx="1"
      transform="matrix(0.704868 -0.709338 0.704868 0.709338 99.5776 69.4043)"
      fill="currentColor"
    />
    <rect
      width="3.34392"
      height="28.0924"
      rx="1"
      transform="matrix(0.704868 0.709338 -0.704868 0.709338 101.476 67.457)"
      fill="currentColor"
    />
  </AccessibleSVG>
)
