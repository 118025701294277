import DOMPurify from 'isomorphic-dompurify'

import type { EmbedBlockContent, PageBlock } from '@microsites/types'

interface EmbedBlockProps {
  block: PageBlock
}

/**
 * Component for rendering HTML provided in embed block on microsite page.
 */
const EmbedBlock: React.FC<EmbedBlockProps> = ({ block }) => {
  const content = block.content as EmbedBlockContent
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(content.html),
      }}
    />
  )
}

export default EmbedBlock
