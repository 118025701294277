import type {
  FWPageBlock,
  FWPageBlockContent,
  FWStoryblockConfig,
} from '@microsites/types'
import { formatFWConfig } from '@microsites/utils/microsites/formatFWConfig'
import { formatFWContent } from '@microsites/utils/microsites/formatFWContent'

interface StoryblockProps {
  username: string
  block: FWPageBlock
}

/**
 * Component for rendering fw-storyblock on a microsite page
 */
const FWStoryblock: React.FC<StoryblockProps> = ({ username, block }) => {
  const content = formatFWContent(block.content as FWPageBlockContent)
  const config = formatFWConfig(block.config as FWStoryblockConfig)
  return (
    <fw-storyblock
      branding="false"
      channel={username}
      {...content}
      {...config}
    />
  )
}

export default FWStoryblock
