import type { FWPageBlockContent } from '@microsites/types'

/**
 * Helper function to format content received from the API into a format that is
 * accepted by the FW blocks.
 */
export const formatFWContent = ({
  video_id,
  playlist_id,
  ...rest
}: FWPageBlockContent) => ({
  video: video_id ?? '',
  playlist: playlist_id ?? '',
  ...rest,
})
