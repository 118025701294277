import { Image } from '@core/components/Image'
import type { ImageBlockContent, PageBlock } from '@microsites/types'

interface ImageBlockProps {
  block: PageBlock
}

/**
 * Component for rendering an image on microsite page.
 */
const ImageBlock: React.FC<ImageBlockProps> = ({ block }) => {
  const { image } = block.content as ImageBlockContent
  return <Image src={image.url} alt={image.alt} width="100%" />
}

export default ImageBlock
