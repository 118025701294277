import styled from '@emotion/styled'

import { HEADER_FOOTER_HEIGHT } from '../constants'

export const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  width: 100%;
  height: ${HEADER_FOOTER_HEIGHT}px;
`

export const CopyrightContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: rgba(0 0 0 / 50%);
`
