/**
 * @fileoverview
 * Next.js SSR does not allow us to use <Suspense /> throwing an error:
 * "Error: ReactDOMServer does not yet support Suspense."
 * Next.js on the other hand provides isomorphic `dynamic` import via 'next/dynamic'
 */
import { forwardRef } from 'react'

import dynamic from 'next/dynamic'

import { chunk } from './chunk'
import type { IProps } from './types'

const Component = dynamic(chunk)

export const Markdown = forwardRef<HTMLDivElement, IProps>((props, ref) => (
  <Component ref={ref} {...props} />
))
