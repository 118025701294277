import styled from '@emotion/styled'

import {
  BLOCK_VERTICAL_MARGIN,
  HEADER_FOOTER_HEIGHT,
} from '@microsites/components/blocks/constants'

export const BlockContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

export const MicrositePageContainer = styled.div<{ hasHeaderBlock: boolean }>`
  ${({ hasHeaderBlock }) =>
    hasHeaderBlock &&
    `
      padding-block-start: ${
        HEADER_FOOTER_HEIGHT + 3 * BLOCK_VERTICAL_MARGIN
      }px;
    `}
`
