import { css } from '@emotion/react'
import { connect } from 'redux-zero/react'

import {
  DividerBlock,
  EmbedBlock,
  FooterBlock,
  FWEmbedFeed,
  FWHeroUnitBlock,
  FWStoryblock,
  HeaderBlock,
  ImageBlock,
  ParagraphBlock,
} from '@microsites/components/blocks'
import {
  BLOCK_HORIZONTAL_MARGIN,
  BLOCK_VERTICAL_MARGIN,
} from '@microsites/components/blocks/constants'
import { MicrositeHead } from '@microsites/components/MicrositeHead'
import { useMicrositeUIConfig } from '@microsites/hooks/useMicrositeUIConfig'
import type { IPWAState } from '@microsites/store'
import type {
  FWPageBlock,
  HeaderBlockContent,
  IMicrositePageProps,
  PageBlock,
} from '@microsites/types'
import { getMicrositeTheme } from '@microsites/utils/microsites/getMicrositeTheme'
import { getPageBlockAppearance } from '@microsites/utils/microsites/getPageBlockAppearance'

import { Container } from '../Container/Container'

import { BlockContainer, MicrositePageContainer } from './MicrositePage.styles'

const renderBlock = (block: PageBlock, username?: string) => {
  switch (block.type) {
    case 'header':
      return <HeaderBlock block={block} />
    case 'footer':
      return <FooterBlock block={block} />
    case 'paragraph':
      return <ParagraphBlock block={block} />
    case 'divider':
      return <DividerBlock block={block} />
    case 'image':
      return <ImageBlock block={block} />
    case 'embed':
      return <EmbedBlock block={block} />
    case 'fw-storyblock':
      return (
        <FWStoryblock username={username ?? ''} block={block as FWPageBlock} />
      )
    case 'fw-carousel':
      return (
        <FWEmbedFeed
          mode="row"
          username={username ?? ''}
          block={block as FWPageBlock}
        />
      )
    case 'fw-grid':
      return (
        <FWEmbedFeed
          mode="grid"
          username={username ?? ''}
          block={block as FWPageBlock}
        />
      )
    case 'fw-herounit':
      return (
        <FWHeroUnitBlock
          username={username ?? ''}
          block={block as FWPageBlock}
        />
      )
    default:
      return null
  }
}

/**
 * Component for rendering the microsite page. This is the main component for
 * microsite custom campaign pages.
 */
export const MicrositePage: React.FC<IMicrositePageProps> = (props) => {
  const { ssrPage, ssrChannel } = props
  useMicrositeUIConfig(ssrChannel, ssrPage)
  const theme = getMicrositeTheme(ssrChannel, ssrPage)
  const headerBlock = ssrPage?.blocks.find((block) => block.type === 'header')
  const headerContent = ssrPage?.blocks.find((block) => block.type === 'header')
    ?.content as HeaderBlockContent

  return (
    <MicrositePageContainer hasHeaderBlock={!!headerBlock}>
      <MicrositeHead
        username={ssrChannel?.username ?? ''}
        title={ssrPage?.title}
        metadata={ssrPage?.metadata}
        image={headerContent && headerContent.logo.url}
        font={ssrChannel?.config?.ui_font}
      />
      {ssrPage?.blocks.map((block) => {
        return (
          <BlockContainer
            css={getPageBlockAppearance(block, theme)}
            key={block.type}
          >
            <Container
              css={[
                css`
                  padding-left: ${BLOCK_HORIZONTAL_MARGIN}px;
                  padding-right: ${BLOCK_HORIZONTAL_MARGIN}px;
                  padding-top: ${block.type !== 'divider'
                    ? BLOCK_VERTICAL_MARGIN
                    : 0}px;
                  padding-bottom: ${block.type !== 'divider'
                    ? BLOCK_VERTICAL_MARGIN
                    : 0}px;
                `,
              ]}
            >
              {renderBlock(block, ssrChannel?.username)}
            </Container>
          </BlockContainer>
        )
      })}
    </MicrositePageContainer>
  )
}

export default connect<IPWAState, IMicrositePageProps>((state) => {
  return {
    params: state.params,
  }
})(MicrositePage)
