import type {
  FWHeroUnitConfig,
  FWPageBlock,
  FWPageBlockContent,
} from '@microsites/types'
import { formatFWConfig } from '@microsites/utils/microsites/formatFWConfig'
import { formatFWContent } from '@microsites/utils/microsites/formatFWContent'

interface HeroUnitBlockProps {
  username: string
  block: FWPageBlock
}

/**
 * Component for rendering fw-herounit on a microsite page
 */
const FWHeroUnitBlock: React.FC<HeroUnitBlockProps> = ({ username, block }) => {
  const content = formatFWContent(block.content as FWPageBlockContent)
  const config = formatFWConfig(block.config as FWHeroUnitConfig)
  return <fw-herounit channel={username} {...content} {...config} />
}

export default FWHeroUnitBlock
