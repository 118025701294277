import { useEffect } from 'react'

import type { IAPI_ChannelConfig } from '@core/api'
import merge from '@core/lodash/merge'
import { pruneNilValues } from '@core/object'
import { store } from '@microsites/store'
import type { Api_Page, MicrositeConfig } from '@microsites/types'

/**
 * Microsite hook to update params in the store based on channel and page returned from SSR
 */
export const useMicrositeUIConfig = (
  ssrChannel?: MicrositeConfig,
  ssrPage?: Api_Page,
) => {
  useEffect(() => {
    if (!ssrChannel) {
      return
    }
    store.setState({
      params: merge<IAPI_ChannelConfig>(
        {},
        store.getState().params as IAPI_ChannelConfig,
        pruneNilValues(ssrChannel.config),
        pruneNilValues(ssrPage?.metadata?.config || {}),
      ),
    })
  }, [ssrChannel, ssrPage?.metadata?.config])
}
