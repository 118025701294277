import type { FC } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import htmr from 'htmr'
import Head from 'next/head'

import type { PageMeta } from '@microsites/types'

import FontHead from '../FontHead/FontHead'

interface Props {
  username: string
  title?: string
  metadata?: PageMeta
  image?: string
  font?: string
}

/**
 * Component for dynamically creating <head> for microsite pages.
 */
export const MicrositeHead: FC<Props> = ({
  username,
  title,
  metadata,
  image,
  font,
}) => {
  const { t } = useTranslation()

  const headTitle = title ?? `${username} - Firework`
  const { meta_description, meta_tags } = metadata ?? {}

  return (
    <>
      <Head>
        <title>{headTitle}</title>
        <meta
          name="description"
          content={meta_description ?? t('next:A New Way to Watch.')}
        />
        <meta property="og:title" content={title} />
        <meta
          property="og:description"
          content={meta_description ?? t('next:A New Way to Watch.')}
        />
        <meta property="og:image" content={image} />
        <meta property="al:ios:url" content={`firework://ch/${username}`} />
        {htmr(meta_tags ?? '')}
        <meta property="al:android:url" content={`firework://ch/${username}`} />
        <meta property="al:web:url" content={`https://fw.tv/${username}`} />
      </Head>
      <FontHead font={font} />
    </>
  )
}
