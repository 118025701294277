import UnstyledLink from '@embed/components/common/UnstyledLink'
import type { SocialLink } from '@microsites/types'

import SocialImage from './SocialImage'

interface SocialButtonProps {
  link: SocialLink
}

/**
 * Component used in the microsite page footer to render social links.
 */
const SocialButton: React.FC<SocialButtonProps> = (props) => {
  const { link } = props

  return (
    <UnstyledLink target="_blank" href={link.url} rel="noreferrer noopener">
      <SocialImage link={link} />
    </UnstyledLink>
  )
}

export default SocialButton
