import { createContext, useContext } from 'react'

import type { UseTranslationResponseWithConditionalI18n } from '@core/react-i18next-lazy/types'

/**
 * TranslationContext to carry translation functions
 * for any component.
 */
export const TranslationContext = createContext<
  UseTranslationResponseWithConditionalI18n<'translation'> | undefined
>(undefined)

/**
 * useContextTranslation hook for translation
 * functions provided by context.
 */
export function useContextTranslation() {
  const transFns = useContext(TranslationContext)
  if (!transFns) {
    throw new Error('Translation functions not found in the context')
  }
  return transFns
}
