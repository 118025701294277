import styled from '@emotion/styled'

import { BLOCK_HORIZONTAL_MARGIN, HEADER_FOOTER_HEIGHT } from '../constants'

export const HeaderBlockContainer = styled.div<{
  logoPosition: 'left' | 'right'
}>`
  display: flex;
  flex-direction: ${({ logoPosition }) =>
    logoPosition === 'right' ? 'row-reverse' : 'row'};
  width: 100%;
  height: ${HEADER_FOOTER_HEIGHT}px;

  & > div:last-child {
    ${({ logoPosition }) =>
      logoPosition === 'right'
        ? `margin-inline-end: ${BLOCK_HORIZONTAL_MARGIN}px;`
        : `margin-inline-start: ${BLOCK_HORIZONTAL_MARGIN}px;`}
  }
`

export const HeaderLogoContainer = styled.div`
  height: 100%;
`
